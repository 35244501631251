$("#select_horaire").on('change', function(e) {
    var document_link = e.target.selectedOptions[0].value;
    console.log(document_link);

    $('#document_horaire')[0].href = document_link

    $($('#document_horaire')[0]).show()
})

$("#select_resultat").on('change', function(e) {
    var document_link = e.target.selectedOptions[0].value;
    $('#document_resultat')[0].href = document_link
    $($('#document_resultat')[0]).show()

})
