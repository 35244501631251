require('../scss/front.scss');

// require('bootstrap');
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap';
import './competition'
import './alert';
import './lightbox';

require('@fortawesome/fontawesome-free/css/all.min.css');
require('@fortawesome/fontawesome-free/js/all.js');

const $ = require('jquery');
global.$ = global.jQuery = $
