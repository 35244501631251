var alerts = $(".alert-modification");

alerts.each(function(index, element) {
    $(element).modal('show')

    setTimeout(function() { $(element).modal('hide'); }, 3000);
})


alerts = $(".alert-error");

alerts.each(function(index, element) {
    $(element).modal('show')

    setTimeout(function() { $(element).modal('hide'); }, 8000);
})
